body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin: 4em;
  margin-top: 130px;
}

.card {
  border-color: var(--chakra-colors-gray-200);
  padding: 2em;
  border-width: 1px;
  border-radius: 12px;
}

.log-out {
  float: right !important;
  margin-top: 12px !important;
  color: white !important;
}

.log-out:hover {
  background: rgb(1, 172, 240) !important;
}

.oneColTable td {
  text-align: center !important;
}

.empty-editable {
  color: red;
}

.login {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.valid-pin {
  border-color: #3ee59281 !important;
  box-shadow: 0 0 0 1px #3ee59281 !important;
}
